import { useEffect } from "react";
import "./App.css";

const App = () => {
  useEffect(() => {
    window.location.href = "https://gametje.com";
  }, []);
  return <></>;
};

export default App;
